import * as FormStyles from '../../styles/FormStyles';
import { ErrorLabel, findError, Label, Loading, SelectInput } from './Form';
import React from 'react';
import { ReactChildren } from '../../utils';

type onChangeEvent = (name: string, value: string) => void;

type LabeledSelectParams = {
    name: string;
    label: string;
    className?: string;
    value: string | null | undefined;
    isLoading?: boolean;
    required?: boolean;
    readonly?: boolean;
    children: ReactChildren;
    loadError?: Error | null;
    formError?: unknown;
    formErrorName?: string;
    onChange?: onChangeEvent;
    onBlur?: onChangeEvent;
    placeholder?: string;
    defaultValue?: string;
    isClearable?: boolean;
};

/** Displays a Label with a InputSelect */
export default function LabeledSelect(params: LabeledSelectParams) {
    let labelText = params.label;
    if (params.required) {
        labelText = `${labelText} *`;
    }

    const errors = findError(params.formError, params.formErrorName ?? params.name);

    return (
        <section className={`${FormStyles.Field} ${params.className}`}>
            <Label name={params.name}>{labelText}</Label>
            <Loading isLoading={!!params.isLoading} />
            {!params.isLoading && (
                <>
                    <SelectInput
                        name={params.name}
                        value={params.value}
                        readOnly={params.readonly}
                        hasError={!!errors}
                        onChange={params.onChange}
                        onBlur={params.onBlur}
                        ariaLabel={'Select'}
                        isClearable={params.isClearable}
                        placeholder={params.placeholder}
                        defaultValue={params.defaultValue}
                    >
                        {params.children}
                    </SelectInput>
                    <ErrorLabel errors={errors} />
                </>
            )}
        </section>
    );
}
